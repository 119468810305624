import axios from "axios";
import ReactGA from "react-ga4";
import { callSuccess, callFailure } from "../actions/ApiCalls";
import {
  disableLocs,
  messageWillbeReceived,
  messageReceived,
  disableUserInput,
  additionalMessageRecieved,
} from "../actions";
import { call, put, select } from "redux-saga/effects";
import * as types from "../constants/ActionTypes";
import { formatAMPM } from "../utils/formatDate";

const getBotState = (state) => state.bot;
const getMessageState = (state) => state.message;
const SERVERPORT = "8083";
//const SERVERPORT = "8082";

const fetchBot = (bot_id) => {
  const storedKey = localStorage.getItem("key");
  //let url = window.location.protocol+"//"+types.SERVER_ADDRESS+":8082/bot/"+bot_id+"?session="+storedKey;
  let urlData = new URL(document.location);
  let pageData = {
    title: urlData.searchParams.get("title"),
    actUrl: urlData.searchParams.get("url"),
    referrer: urlData.searchParams.get("referrer"),
    keyword: urlData.searchParams.get("keyword"),
    gclid: urlData.searchParams.get("gclid"),
    msclkid: urlData.searchParams.get("msclkid"),
    option: urlData.searchParams.get("option"),
    trigger: urlData.searchParams.get("trigger"),
  };
  let { title, actUrl, referrer, keyword, gclid, msclkid, option, trigger } =
    pageData;
  let paramData =
    "&title=" +
    encodeURI(title) +
    "&referrer=" +
    encodeURIComponent(referrer) +
    "&keyword=" +
    encodeURI(keyword) +
    "&gclid=" +
    encodeURI(gclid) +
    "&msclkid=" +
    encodeURI(msclkid) +
    "&url=" +
    encodeURIComponent(actUrl) +
    "&option=" +
    encodeURI(option);
  paramData += "&trigger=" + trigger;
  const url =
    window.location.protocol +
    "//" +
    types.SERVER_ADDRESS +
    ":" +
    SERVERPORT +
    "/bot/" +
    bot_id +
    "?session=" +
    storedKey +
    paramData;
  //console.log(url)
  return axios({
    method: "get",
    url: url,
  });
};

export function* fetchBotDetails(action) {
  try {
    const response = yield call(fetchBot, action.bot_id);
    const data = response.data;
    const operators = response.data.operators;
    delete response.data.operators;

    localStorage.setItem("agent_id", data.agentId);
    // dispatch a success action to the store with the new dog
    data.webchat_settings = JSON.parse(data.webchat_settings);
    if (data.webchat_widget !== "") {
      data.webchat_widget = JSON.parse(data.webchat_widget);
      //console.log(data.webchat_widget + "why here ");
    } else {
      data.webchat_widget = {};
    }
    // if (data.webchat_widget !== '') {
    //     data.webchat_widget = JSON.parse(data.webchat_widget);
    //     console.log(data.webchat_widget + "why here ");
    // }
    // else {
    //     data.webchat_widget = {};
    // }
    if (!data.error) {
      if (data != null && data.ga4Tag != null)
        if (data.ga4Tag != "") {
          console.log("initialized ga 4");
          //ReactGA.initialize(data.ga4Tag, {'cookieFlags': 'samesite=none;secure'});
        }
      yield put(callSuccess("BOT_CALL_SUCCESS", data));
      yield put(callSuccess("OPERATORS_CALL_SUCCESS", operators));
    } else yield put(callFailure("BOT_CALL_FAILURE", true));
  } catch (error) {
    //console.log(error);
    yield put(callFailure("BOT_CALL_FAILURE", error));
  }
}

const reverseGeoCode = async (zipCode) => {
  const geoCodeReqUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=AIzaSyA7IqkN50oJ4oP9jD6mQ2yTK7d4_Lx_C5A`;
  // show loading here
  //console.log(encodeURI(geoCodeReqUrl));
  const req = new Request(encodeURI(geoCodeReqUrl));
  const geoCodeResult = await fetch(req);
  const geoCodeResp = await geoCodeResult.json();
  return geoCodeResp;
};

const distance = (zipLat, zipLon, lat2, lon2, unit) => {
  var radlat1 = (Math.PI * zipLat) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = zipLon - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit == "K") {
    dist = dist * 1.609344;
  }
  if (unit == "N") {
    dist = dist * 0.8684;
  }
  return dist;
};

export function* fetchLatLon() {
  const state = yield select(getBotState);
  const messages = yield select(getMessageState);
  var filteredMessages = messages.chats.filter(function (itm) {
    return itm.author === "Me";
  });
  const lastMessage = filteredMessages[filteredMessages.length - 1];
  yield put(messageWillbeReceived(true));
  const geoCode = yield call(reverseGeoCode, lastMessage.message);
  let location_geo_code = {};
  if (geoCode.results.length > 0) {
    location_geo_code = geoCode.results[0].geometry.location;
  }
  yield put(disableLocs());
  const cDate = new Date();
  const finalDate = formatAMPM(cDate);
  const nearestLocations = sortedLocations(state, location_geo_code);
  const companyName = state.data.company_name;
  let locCount = nearestLocations.length;
  let countText = "three";
  if (locCount < 5) countText = locCount;
  let markup =
    "Here are " + countText + " nearby locations based on your zip code.";
  yield put(messageReceived(markup, "bot", 0, finalDate, false));
  yield put(messageWillbeReceived(true));
  let cMarkup = `Please select your preferred location to continue.`;
  let c = 0;
  for (let x in nearestLocations) {
    if (c < 5) {
      cMarkup +=
        '<button data-type="qrb" type="button" class"quick_reply">' +
        nearestLocations[x].locationName +
        "</button>";
      c++;
    }
  }
  /*setTimeout(function (){ 
        delayLocs(markup, finalDate);
        console.log('inside dealay');
    }, 2000);*/
  yield call(delay, 2000);
  yield put(additionalMessageRecieved(cMarkup, 1, finalDate, false));
  yield put(disableUserInput());
}

export function* sendRMRequest(bot_id) {
  const storedKey = localStorage.getItem("key");
  const url =
    window.location.protocol +
    "//" +
    types.SERVER_ADDRESS +
    ":" +
    SERVERPORT +
    "/rmessage/" +
    bot_id +
    "?session=" +
    storedKey;
  //console.log(url)
  const data = yield axios({
    method: "get",
    url: url,
  });
  if (!data.error) {
    yield put(callSuccess("RM_CALL_SUCCESS"));
  } else yield put(callFailure("RM_CALL_FAILURE", true));
}

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
const sortedLocations = (state, location_geo_code) => {
  const storeLocations = state.data.locations;
  const locs = [];
  for (let x in storeLocations) {
    let storeLocationGCode = storeLocations[x].geo_code;
    let dist = distance(
      location_geo_code.lat,
      location_geo_code.lng,
      storeLocationGCode.lat,
      storeLocationGCode.lng
    );
    let locationName = storeLocations[x].name;
    locs.push({ dist, locationName });
  }
  locs.sort(function (a, b) {
    return a.dist - b.dist;
  });
  return locs;
};
