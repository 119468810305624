import ReactGA from "react-ga4";
import * as types from "../constants/ActionTypes";
import { formatAMPM } from "../utils/formatDate";
import { searchandDelay } from "../utils/parser";
import { streamMessageReceived } from "../actions";
import { parseMessage } from "../utils/parser";
import { initCall, socketClosed, loadPersist } from "../actions/ApiCalls";
import { gtag } from "../utils/gtag";
import queryString from "query-string";

const generateKey = (se) => {
  return `${se}_${new Date().getTime()}`;
};
let timer = 1000;
const widget_incoming = document.getElementById("widget_incoming2");

const addStreammessage = (data, dispatch) => {
  let author = data.author;
  let feedBack = data.feedBack;
  let cDate = new Date();
  let finalDate = formatAMPM(cDate);
  let lmore = "";
  if (data.lmore) lmore = data.lmore;
  let mesage = data.message;
  if (data.sub_type != "STREAM_FINAL")
    mesage = `${data.message}<span style="font-size:20px; color:white;"> &#9679;</span>`;
  if (timer <= 1500) {
    dispatch(streamMessageReceived(mesage, author, finalDate, feedBack, lmore));
    timer += 85;
  } else {
    setTimeout(() => {
      dispatch(
        streamMessageReceived(mesage, author, finalDate, feedBack, lmore)
      );
    }, timer);
    timer += 85;
  }

  if (data.sub_type == "STREAM_FINAL") timer = 1000;
};

const setupSocket = (dispatch, store) => {
  const storedKey = localStorage.getItem("key");
  console.log("calling ga4 event");
  setTimeout(() => {
    let state = store.getState();
    if (state.bot.data != null) {
      if (
        (state.message.chats.length == 0 || state.message.chats.length == 1) &&
        state.bot.data.ga4Tag != ""
      ) {
        if (state.bot.data.ga4Tag != null) {
          console.log("calling ga4 event");
          ReactGA.event("chat_open", {
            item_category: "init",
          });
        }
      }
    }
  }, 8000);

  let key = "";
  if (storedKey) {
    key = storedKey;
  } else {
    key = generateKey("sess");
    localStorage.setItem("key", key);
  }
  const { bot_id } = queryString.parse(window.location.search);
  //const socket = new WebSocket('ws://'+types.SERVER_ADDRESS+':8989?id='+key+"&bot_id="+bot_id);
  const socket = new WebSocket(
    "wss://" + types.SERVER_ADDRESS + ":8988?id=" + key + "&bot_id=" + bot_id
  );

  socket.onopen = () => {
    //console.log("socket loaded should send request here");
    const { bot_id } = queryString.parse(window.location.search);
    //let bot_id = bot_id;
    dispatch(initCall("BOT_CALL_REQUEST", bot_id));
  };
  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const state = store.getState();
    const storedState = localStorage.getItem(`reduxState_${bot_id}`);
    let messages = 0;
    if (storedState !== null) {
      const { message } = JSON.parse(storedState);
      if (message.chats.length > 0) {
        messages = message.chats.length;
      }
    }
    console.log("recieved data is from socket ", data);
    switch (data.type) {
      case types.ADD_MESSAGE:
        if (messages > 0 && data.sub_type == "WELCOME") {
          console.log("WELCOME");
          dispatch(loadPersist());
        } else {
          if (!isNaN(data.author)) {
            widget_incoming.play();
          }
          searchandDelay(data, dispatch);
        }
        break;
      case "ADD_MESSAGE_STREAM":
        addStreammessage(data, dispatch);
        break;
      case "GTAG_EVENT":
        //console.log(data);
        gtag(data);
        break;
      case "ADD_MESSAGE_POST_INTENT_FULLFILLMENT":
        if (state.bot.data.ga4Tag != "") {
          if (state.bot.data.ga4Tag != null) {
            console.log("calling ga4 event intent complete");
            ReactGA.event("chatbot_leads", {
              item_category: "lead",
            });
          }
        }
        searchandDelay(data, dispatch, true);
        break;
      case "INTENT_COMPLETE":
        if (state.bot.data.ga4Tag != "") {
          if (state.bot.data.ga4Tag != null) {
            console.log("calling ga4 event intent complete");
            ReactGA.event("chatbot_leads", {
              item_category: "lead",
            });
          }
        }
        break;
      default:
        break;
    }
  };

  socket.onclose = function () {
    console.log("socket on close event");
    dispatch(socketClosed());
  };
  return socket;
};

export default setupSocket;
